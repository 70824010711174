import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PageHead from 'src/components/head/PageHead'
import { NewsletterIcon, UnsubscribeForm, SubscribeSuspended } from 'src/components/newsletter'

import { SUBSCRIBE_PREFERENCES_SUSPENDED } from 'src/constants'

// Styles & Images
import 'static/newsletter/scss/styles.scss'

// helpers.
const page = 'investor-newsletter-unsubscribe'

// Partials
const Background = () => (
  <>
    <div className="x-page-newsletter__background" />
    <div className="x-page-newsletter__background-x-container">
      <img src="/img/header-x-long.svg" />
    </div>
  </>
)

// Main component:
const UnsubscribeModal = ({ location }) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => setTimeout(setAnimate(true), 1), [])

  return (
    <div
      className={classNames('x-page-newsletter', {
        'x-page-newsletter--animating': animate,
      })}
    >
      <Background />

      <div className="x-page-newsletter__content">
        <NewsletterIcon animate={animate} />

        {SUBSCRIBE_PREFERENCES_SUSPENDED ? (
          <SubscribeSuspended animate={animate} />
        ) : (
          <UnsubscribeForm
            animate={animate}
            location={location}
            page={page}
            endpoint="users/investors-cancel-subscription"
          />
        )}
      </div>
    </div>
  )
}

// Main component props:
UnsubscribeModal.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

UnsubscribeModal.defaultProps = {}

export default UnsubscribeModal

// <head> component:
export function Head() {
  return <PageHead page={page} noindex />
}
